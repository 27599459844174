.home {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #a8b4b4;
}

.navbar {
  padding: 60px 0 0px;
  text-align: center;
  background-color: #041c1e;
}

.navbar h1 {
  font-size: x-large;
  color: white;
}

.nv {
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow-x: hidden;
}

.navbar .navig {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 20px;
  width: fit-content;
  float: right;
}

.navig a {
  display: block;
  float: right;
  font-size: medium;
  max-width: 120px;
  text-decoration: none;
  padding: 15px 18px;
  color: #f5fefe;
  white-space: nowrap;
}

.navig .active {
  border-bottom: 3px solid #f5fefe;
}

.welcomepage {
  max-width: 600px;
  margin: auto;
}

.intro {
  margin: 20px 10px;
  border-radius: 8px;
  background-color: lightgrey;
  padding: 20px;
  font-size: large;
  letter-spacing: 1px;
  line-height: 25px;
}

.intro h2 {
  text-align: center;
  font-size: large;
}

.intro hr {
  margin: 10px auto 15px;
}

.intro p {
  padding: 10px;
}

.intro a {
  text-decoration: none;
  display: block;
  color: #f5fefe;
  margin: 25px 25px 10px;
  background-color: #032326;
  width: fit-content;
  border-radius: 5px;
  padding: 10px 20px;
  transition: 00.3s ease-in-out;
}

.intro a:hover {
  background-color: #063135;
}

.navstart, .subtn {
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}

button {
  border: none;
  outline: none;
  background-color: #fdedba;
  min-width: 100px;
  padding: 10px 20px;
  font-size: large;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: black 0px 2px 7px 0px;

}

button:hover {
  font-size: larger;
  box-shadow: black 2px 4px 7px 0px;
}

.footer {
  padding: 20px;
  background-color: #032326;
}

.footer .hd {
  text-align: center;
  font-size: larger;
  color: #eff5f5;
}

.footer form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin: auto;
  max-width: 500px;
}

form .input {
  margin: 8px;
  width: 100%;
}

.footer input {
  border: none;
  outline: 1px solid white;
  padding: 10px;
  font-size: large;
  width: 100%;
  border-radius: 8px;
}

.footer textarea {
  resize: none;
  width: 100%;
  padding: 10px;
  font-size: large;
  border-radius: 8px;
}

.footer button {
  padding: 7px 5px;
  width: 100%;
}

.test {
  width: 100%;
  background-color: #a8b4b4;
  padding-bottom: 20px;
}

.test h1, .result h1 {
  padding: 20px;
  text-align: center;
  font-size: x-large;
}

.test hr, .result hr {
  max-width: 500px;
  width: 80%;
  display: block;
  margin: auto;
}

.test .nmcount {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.testnum, .testcount {
  font-size: larger;
  font-weight: bolder;
  white-space: nowrap;
}

.canceltest a {
  border-radius: 5px;
  box-shadow: 0 2px 7px 0 #000;
  cursor: pointer;
  font-size: medium;
  outline: none;
  padding: 10px 10px;
  transition: .2s ease-in-out;
  text-decoration: none;
  color: black;
  white-space: nowrap;
  background-color: #f5fefe;
}

.testboard {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  background-color: lightgrey;
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.error {
  margin: 150px auto;
  display: flex;
  width: fit-content;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.loader {
  border: 5px solid #f5fefe;
  border-top: 5px solid #247e84;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.question {
  padding: 10px 20px;
  text-align: justify;
}

.choices {
  display: flex;
  flex-direction: column;
  padding: 20px 5px;
  flex-wrap: nowrap;
}

.nch {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 10px;
  background-color: #f5fefe;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: 00.2s ease-in-out;
}

.test .nch:hover {
  background-color: #ddeeee;
}

.nch .chtxt {
  align-items: center;
  display: flex;
}

.count {
  margin: 3px 14px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  
}


.chosen.countCh {
  color: white;
  border: 3px solid #003e3e;
  background-color: #007e84;
}

.result .chosen.countCh {
  border: 3px solid #2f0000;
  background-color: #f6d6d6;
  color: black;
}

.result  .trcan .countCh {
  color: white;
  border: 3px solid #003e3e;
  background-color: #007e84;
}

.countCh {
  width: 40px;
  height: 40px;
  background-color: #afc6c6;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.nexpre {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.qnav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #a8b4b4;
  border-radius: 5px;
  justify-content: flex-start;
  padding: 10px;
}

.qnav div {
  width: 40px;
  padding-top: 10px;
  height: 40px;
  border-radius: 4px;
  background-color: #f5fefe;
  margin: 4px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: 00.2s ease-in-out;
}

.qnav .answered {
  border: 2px solid #004040;
}

.qnav .active {
  background-color: #247e84;
  color: white;
}

.qnav div:hover {
  background-color: #d4e9e9;
  color: #003e3e;
}

.subtnq {
  padding: 10px;
  width: fit-content;
  margin: auto;
}

.result {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color:#a8b4b4;
  border-radius: 15px;
}

.resport {
  font-size: xx-large;
  margin: 30px auto;
  text-align: center;
}

.answerprev {
  background-color: lightgray;
  padding: 20px 0;
  margin-bottom: 20px;
  border-radius: 15px;
}

.notfound {
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.notfound div {
  width: fit-content;
  margin: auto;
}

.notfound .mssg {
  font-size: 100px;
  font-weight: bolder;
  padding: 20px;
}

.notfound .info {
  font-size: xx-large;
  margin-bottom: 30px;
}

.plate {
  background-color: #a8b4b4;
  width: 100%;
  top: 0;
  min-height: 100vh;
  position: absolute;
}

.plate_plan {
  background-color: #d3d3d3;
  width: 100%;
  max-width: 600px;
  margin: 10px auto 50px;
  border-radius: 15px;
  overflow: hidden;
}

.plate_plan .hd {
  display: flex;
  justify-content: baseline;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 10px;
}

.backnav {
  width: fit-content;
  font-size: xx-large;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.backnav:hover {
  box-shadow: 0px 0px 7px;
}

.backnav a {
  text-decoration: none;
  color: #032326;
  display: block;
  width: 100%;
  padding: 4px 25px;
}

.inhd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: bold;
}

.plateCont {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.plateCont h1 {
  text-align: center;
  font-size: larger;
  padding: 40px 10px;
}

.plCont {
  display: flex;
  flex-direction: row;
  background-color: #f5fefe;
  border-radius: 5px;
  margin: 5px 5px 10px;
}

.plate_img {
  padding: 5px;
}

.plate_img img {
  width: 100%;
  max-width: 200px;
  min-width: 60px;
}

.plate_meaning {
  display: flex;
  padding: 10px;
  align-items: center;
}

.bookBlogs {
  max-width: 600px;
  margin: auto;
}

.bookBlogs h1 {
  text-align: center;
  font-size: x-large;
  padding: 40px 10px;
}

.bookBlogs hr {
  width: 80%;
  max-width: 500px;
  display: block;
  margin: auto auto 40px;
}

.bookblogCont {
  background-color: lightgray;
  margin: 10px 5px;
  padding: 20px;
  border-radius: 8px;
}

.bookblogCont .heading {
  margin: 5px;
}

.heading h1 {
  font-weight: 600;
  font-size: larger;
}

.heading h2 {
  font-weight: 500;
  font-size: larger;
}

.sampleBody {
  padding: 5px;
  letter-spacing: 1px;
}

.sampleBody a {
  display: block;
  margin-top: 15px;
  text-decoration: none;
  color: #f5fefe;
  background-color: gray;
  width: fit-content;
  border-radius: 8px;
  padding: 10px 15px;
  transition: 00.3s ease-in-out;
}

.sampleBody a:hover {
  background-color: darkgray;
}

.blogCont {
  width: 100%;
  max-width: 600px;
  margin: auto;
  background-color: lightgrey;
  border-radius: 8px;
}

.myblog {
  padding: 20px;
}

.blohd {
  margin: 15px;
  display: flex;
}
.blohd div {
  padding: 0px 15px;
}

.notsh {
  width: fit-content;
  white-space: nowrap;
}

.blohd h1 {
  font-weight: 600;
  font-size: larger;
}

.blohd h2 {
  font-weight: 500;
  font-size: medium;
}

.headsubhd {
  display: flex;
  flex-wrap: nowrap;
}

.headsubhd h5 {
  font-size: large;
  font-weight: 500;
  white-space: nowrap;
  padding-right: 10px;
}

.headsubhd h4 {
  font-size: larger;
  font-weight: 600;
  padding-right: 10px;
}

.partCont h6{
  font-size: large;
  padding: 10px 3px;
}

.blogBody {
  padding: 5px;
}

.blogBody p {
  letter-spacing: 1px;
  font-size: large;
  margin-bottom: 15px;
}

@media only screen and (max-width: 380px) {
  .navbar .navig {
      justify-content: center;
      width: 100%;
      float: initial;
  }

  .navig a {
      width: 100%;
      padding: 15px 10px;
      text-align: center;
  }

  .testnum, .testcount {
      font-size: medium;
  }

  .result button {
      font-size: medium;
      padding: 12px 20px;
      min-width: 50px;
  }

  .canceltest {
      margin: auto;
  }
}